import { useCallback, useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import {
    ASSETS_PATH,
    BATTERY_HEALTH_PATH,
    DEFAULT_PATH,
    HISTORY_PATH,
    OVERVIEW_PATH,
} from '../../../../configuration/setup/path';
import OverviewContainer from '../../overview/containers/Overview.container';
import { MainProperties } from './types';
import BatteryHealth from '../../batteryHealth/BatteryHealth';
import HistoryWrapper from '../../history/HistoryWrapper';
import { BATTERY_HEALTH_DETAILS_PATH } from '../../common/constants/paths/paths';
import { useFeatureToggle } from '../../common/hooks/useFeatureToggle';
import { FeatureToggles } from '../../../../configuration/featureToggle/featureToggles';
import Assets from '../assets/Assets';

const Main = ({
    fetchElectricVehiclesFromStateService,
    fetchElectricVehiclesFromMonitoringService,
    accessToken,
}: MainProperties): JSX.Element => {
    const initialFetches = useCallback(() => {
        fetchElectricVehiclesFromStateService(accessToken);
        fetchElectricVehiclesFromMonitoringService(accessToken);
    }, [accessToken, fetchElectricVehiclesFromStateService, fetchElectricVehiclesFromMonitoringService]);
    const { value: isBatteryHealthEnabled } = useFeatureToggle(FeatureToggles.BATTERY_HEALTH);

    useEffect(() => {
        initialFetches();
    }, [initialFetches]);

    return (
        <Switch>
            <Route path={ASSETS_PATH} component={Assets} />
            <Route path={OVERVIEW_PATH} component={OverviewContainer} />
            {isBatteryHealthEnabled && (
                <Route path={[BATTERY_HEALTH_DETAILS_PATH, BATTERY_HEALTH_PATH]} component={BatteryHealth} />
            )}
            <Route path={HISTORY_PATH} component={HistoryWrapper} />
            <Redirect to={DEFAULT_PATH} />
        </Switch>
    );
};

export default Main;
